import { Alert, Divider, Typography } from '@mui/material'
import { useHookstate } from '@hookstate/core'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import SignoutLayout from '../../components/layout/signout_layout'
import SignInForm from '../../components/forms/sign_in'
import Head from '../../components/head'
import redirectIfSignIn from '../../helpers/redirect_if_sign_in'
import app from 'config/app'
import appState from '@/hook_states/app'

export const getServerSideProps = redirectIfSignIn

export default function SignIn(props) {
  const router = useRouter()
  const errorMessage = useHookstate('')
  const onSubmit = async (e) => {
    errorMessage.set('')
    appState.loading.set(true)
    const result = await signIn('credentials', {
      redirect: false,
      email: e.email,
      password: e.password
    })

    if (result.ok) {
      errorMessage.set('')
      router.reload()
      return
    } else if (
      result.error &&
      result.error === 'Your account email is not confirmed'
    ) {
      errorMessage.set(result.error)
    } else if (
      result.error &&
      result.error === 'Your account has been blocked by an administrator'
    ) {
      errorMessage.set(
        'Your account has been blocked, please contact us or your organization admin if this is a mistake.'
      )
    } else {
      errorMessage.set('Credentials is not valid.')
    }

    appState.loading.set(false)
  }

  return (
    <SignoutLayout centerVersion={false}>
      <Head title="Sign In" />
      <Typography
        sx={{
          color: '#000000',
          fontSize: '21px',
          fontWeight: '400',
          lineHeight: '36px',

          '& span': {
            color: '#1D427C',
            fontWeight: '600'
          }
        }}
      >
        Welcome to <span>{app.name}</span>
      </Typography>

      <Typography variant="h4" className="SignoutHeaderText">
        Sign In
      </Typography>

      <Divider color="#D42328" />

      {errorMessage.get() ? (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorMessage.get()}
        </Alert>
      ) : (
        false
      )}

      <SignInForm onSubmit={onSubmit} />
    </SignoutLayout>
  )
}
