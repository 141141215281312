import sessionStorage from 'sessionstorage'
import {
  Box,
  Container, Paper, Typography, useTheme,
} from '@mui/material'
import {
  useEffect,
  useRef,
  useState,
} from 'react'
import Image from 'next/image'
import MainLayout from './main_layout'

export default function SignoutLayout({centerVersion, children}) {
  const theme = useTheme()
  const [documentHeight, setDocumentHeight] = useState('50%')

  useEffect(() => {
    sessionStorage.removeItem('jwt')
    sessionStorage.removeItem('user')
    const resizeInit = () => {
      setDocumentHeight((document.documentElement.scrollHeight / 2) + 'px')
    }
    window.addEventListener('resize', resizeInit)
    resizeInit()

    return () => {
      window.removeEventListener('resize', resizeInit)
    }
  }, [])

  return (
    <MainLayout>
      <Box
        sx={{
          minHeight: '100vh',
          ...(centerVersion ? {
            background: '#1D427C'
          }: {
            backgroundImage: `linear-gradient(#1D427C ${documentHeight}, ${documentHeight}, white)`
          })
        }}
      >
        <Container
          maxWidth='lg'
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              px: 0,
            }
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'center',
              gap: 10,
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                alignItems: 'center',
                gap: 5,
                pt: 5,
              },
              ...(centerVersion && {
                flexDirection: 'column',
                alignItems: 'center',
              }),

            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',

                ...(centerVersion && { display: 'none' })
              }}
            >
              <Box sx={{
                display: 'none',
                textAlign: 'center',

                [theme.breakpoints.down('md')]: {
                  display: 'block'
                }
              }}>
                <Image
                  src='/images/logo signin.png'
                  width='206.68px'
                  height='82px'
                />
              </Box>
              <Box sx={{
                height: '50%',
                maxHeight: documentHeight,
                display: 'flex',
                alignItems: 'end',
                paddingBottom: '52px',
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              }}>
                <Image
                  src='/images/logo signin.png'
                  width='504.1px'
                  height='200px'
                />
              </Box>
              <Box
                sx={{
                  pt: 4,
                  height: '200px',
                  fontFamily: 'IBM Plex Sans',
                  color: '#1D427C',
                  fontStyle: 'normal',

                  [theme.breakpoints.down('md')]: {
                    display: 'none'
                  }
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '34px',
                  }}
                >
                  Welcome 👋
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '24px',
                    mb: 5,
                  }}
                >
                  Please login to your account
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '300',
                    fontSize: '16px',
                    maxWidth: '400px',
                  }}
                >
                  Full-Arch Latched Surgical Guide Design
                </Typography>
              </Box>
            </Box>

            <Paper
              sx={{
                boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.08)',
                borderRadius: '40px',
                p: "46.86px",
                maxWidth: '574px',
                [theme.breakpoints.down('md')]: {
                  p: '30px',
                },

                ...(centerVersion && { maxWidth: '621px' }),
              }}
            >
              {children}
            </Paper>
          </Box>
        </Container>
      </Box>
    </MainLayout>
  )
}