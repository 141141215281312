import React from "react";
import { useForm } from "react-hook-form";
import { TextField, Button, Grid, InputAdornment, Typography, } from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Link from "next/link";

export default function SignIn({ onSubmit }) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={4} spacing={0} mt={1}>
        <Grid item xs={12}>
          <TextField
            label="Email address or username"
            error={!!errors.email}
            helperText={errors.email?.message}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              )
            }}
            {...register("email", { required: "Email is required" })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            type="password"
            error={!!errors.password}
            helperText={errors.password?.message}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              )
            }}
            {...register("password", { required: "Password is required" })}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: 'right',
              mt: 3,
              mb: 7,
              color: '#4285F4',

              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            <Link href='/users/forgot_password'>
              Forgot password?
            </Link>
          </Typography>

          <Button
            variant="contained"
            type="submit"
            size="large"
            color="redButton"
            fullWidth
          >
            Sign In
          </Button>
        </Grid>

      </Grid>
    </form>
  );
}