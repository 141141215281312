import { useHookstate } from "@hookstate/core";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import appState from "../../hook_states/app";

const ConfimationDialog = () => {
  const confirmDialog = useHookstate(appState.confirmDialog)

  if ( !confirmDialog.value ) {
    return false;
  }

  const onClose = () => confirmDialog.set(false)

  return (
    <Dialog
      open={true}
      id="confirmation-dialog"
      onClose={onClose}
    >
      <DialogTitle>
        {confirmDialog.title.value}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {confirmDialog.content.get({noproxy: true})}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {confirmDialog.get({noproxy: true}).onThird &&
          <Button
            onClick={confirmDialog.get({noproxy: true}).onThird}
          >
            {confirmDialog.thirdLabel.value}
          </Button>
        }
        <Button
          onClick={onClose}
        >
          {confirmDialog.noLabel.value || 'No'}
        </Button>
        <Button
          variant="contained"
          color={confirmDialog.severity.value || "secondary"}
          onClick={confirmDialog.get({noproxy: true}).onYes}
        >
          {confirmDialog.yesLabel.value || 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfimationDialog