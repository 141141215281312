import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

const BlockingAlert = ({ open, onClose, title, content, onOk }) => {
  if ( !open ) {
    return false;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      { onOk && (
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={onOk}
          >
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default BlockingAlert