import { Backdrop, Box, Typography, useTheme } from "@mui/material"
import RiseLoader from "react-spinners/RiseLoader";

const LoadingWindow = ({loading}) => {
  const theme = useTheme();

  if ( !loading ) {
    return false;
  }

  return (
    <Backdrop
      sx={{
        color: "secondary.main",
        zIndex: 9999,
        bgcolor: "rgba(0, 0, 0, 0.1)",
        flexDirection: "column"
      }}
      open={true}
    >
      <RiseLoader
        color={theme.palette.secondary.main}
        loading={true}
      />
      <Typography variant="body1" component='p' sx={{mt: 3}}>
        Loading...
      </Typography>
    </Backdrop>
  )
}

export default LoadingWindow