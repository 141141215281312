import { useHookstate } from "@hookstate/core"
import { Alert, Slide, Snackbar } from "@mui/material"
import appState from "../../hook_states/app"
import BlockingAlert from "../shared/blocking_alert"
import ConfimationDialog from "../shared/confirmation_dialog"
import LoadingWindow from "../shared/loading_window"


const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
}

const MainLayout = ({children}) => {
  const app = useHookstate(appState)

  return (
    <>
      <LoadingWindow loading={app.loading?.get({noproxy: true})} />

      { app.globalMessage.value && (
        <Snackbar
          open={true}
          onClose={() => app.globalMessage.set(false)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
          TransitionComponent={SlideTransition}
        >
          <Alert onClose={() => app.globalMessage.set(false)} severity={app.globalMessage.severity?.value}>
            {app.globalMessage?.message?.value}
          </Alert>
        </Snackbar>
      )}

      <ConfimationDialog />

      <BlockingAlert
        open={!!app.blockingAlert.get({noproxy: true})}
        title={app.blockingAlert?.title?.get({noproxy: true})}
        content={app.blockingAlert?.content?.get({noproxy: true})}
        onOk={app.blockingAlert.get({noproxy: true})?.onOk}
      />

      {children}
    </>
  )
}

export default MainLayout