import { hookstate } from '@hookstate/core';

const appState = hookstate({
  activePage: "",
  loading: false,
  globalMessage: false,
  confirmDialog: false,
  blockingAlert: false,
})

export default appState